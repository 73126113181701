<template>
  <div>
    <section>
      <Breadcrumb
        title="Terapist"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/terapist', text: 'Terapist' },
        ]"
      />
    </section>
    <!--Breadcrumb-->

    <!--/Breadcrumb-->
    <div class="open-filter-dialog" @click="filterOpen">
      <i class="si si-equalizer"></i>
    </div>
    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-12" id="mobileEx">
            <!--Add lists-->
            <div class="mb-lg-1">
              <template v-if="isExpertLoad">
                <ProfileItemSkeleton :list="8" />
              </template>
              <template v-if="!isExpertLoad">
                <ProfileItem :list="list" />
              </template>
              <div class="center-block text-center mt-5">
                <Pagination
                  :currentpage="currentpage"
                  :pagesize="pagesize"
                  :preload="pageUrl"
                />
              </div>
            </div>
            <!--Add lists-->
          </div>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document" style="width: 300px">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="input-group mb-3 text-primary">
                    <input
                      type="text"
                      class="form-control br-ts-7 br-bs-7"
                      placeholder="Terapistler arasında"
                      v-model="search"
                      id="search"
                    />
                    <div class="input-group-text border-0 bg-transparent p-0">
                      <button
                        type="button"
                        @click="getList"
                        class="btn btn-primary br-te-7 br-be-7"
                      >
                        Ara
                      </button>
                    </div>
                  </div>

                  <select
                    @change="locationChange"
                    name="city"
                    class="form-control text-primary mb-3"
                  >
                    <option disabled selected>Şehir Seç</option>
                    <option
                      v-for="(item, i) in cityList"
                      :selected="item.name == city"
                      :value="item.shortname"
                      :key="i"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <select
                    v-if="districtList.length > 0"
                    @change="disctrictChange"
                    name="district"
                    class="form-control text-primary mb-3"
                  >
                    <option disabled selected>İlçe Seç</option>
                    <option
                      v-for="(item, i) in districtList"
                      :key="i"
                      :value="item.shortname"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <select
                    name="experiencesList"
                    id="experiencesList"
                    class="form-control text-primary mb-3"
                    @change="searchExperience"
                  >
                    <option disabled selected>Terapi Seç</option>
                    <option
                      v-for="(item, i) in serviceList"
                      :key="i"
                      :value="item.experience_name"
                    >
                      {{ item.experience_name }}
                    </option>
                  </select>
                  <div
                    v-for="(item, i) in filter"
                    :key="i"
                    :style="i == 0 ? 'margin-top:30px' : 'margin-top:20px'"
                  >
                    <h4
                      class="form-check-label mb-2"
                      :for="'cb-' + i"
                      style="
                        border: 1px solid #e9ebf3;
                        padding: 10px;
                        border-radius: 5px;
                        width: fit-content;
                        font-size: 15px;
                        color: #944669;
                      "
                    >
                      {{ item.title }}
                    </h4>
                    <div class="card-body text-primary" style="padding: 0px">
                      <div
                        class="row"
                        style="padding-left: 45px"
                        :key="'cb-' + i"
                      >
                        <div
                          :style="
                            item.type == 'radio' ? 'margin-left:-20px' : ''
                          "
                          :class="
                            'col-12 form-check ' +
                            (item.type == 'check' ? 'form-switch ' : '') +
                            'mb-2'
                          "
                          v-for="(filterObject, a) in item.result"
                          :key="'fo-' + i + '-' + a"
                        >
                          <template v-if="item.type == 'check'">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="checkbox6"
                              :id="'fo-' + i + '-' + a"
                              @click="filterItem(filterObject[1], i, item.type)"
                            />
                            <label
                              class="form-check-label"
                              :for="'fo-' + i + '-' + a"
                              >{{ filterObject[0] }}
                            </label>
                          </template>
                          <template v-if="item.type == 'radio'">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="checkbox6"
                              :id="'fo-' + i + '-' + a"
                              @click="filterItem(filterObject[1], i, item.type)"
                            />
                            <label
                              style="margin-left: 10px"
                              class="form-check-label"
                              :for="'fo-' + i + '-' + a"
                              >{{ filterObject[0] }}
                            </label>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="filterGO"
                  >
                    Filtreyi Uygula
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="filterClose"
                  >
                    Kapat
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-12" id="mobileEx">
            <Item1 :item="ads" />
            <Experience />
          </div>
        </div>
      </div>
    </section>
    <!--Add Listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.open-filter-dialog {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 12px;
  background: #c488a4cb;
  border-radius: 11px;
  top: 50%;
  text-align: center;
  font-size: 34px;
  z-index: 1050;
  color: #fff;
}
</style>
<script>
import Search from "../components/Search.vue";
import Experience from "./SearchPages/Components/Experience.vue";
import ProfileItem from "./SearchPages/Components/ProfileItem.vue";
import ProfileItemSkeleton from "./SearchPages/Components/ProfileItemSkeleton.vue";
import Pagination from "../components/Pagination.vue";
import Item1 from "../components/Ads/Item1.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let cityInfo = {};
    if (this.$route.params.filter) {
      if (this.$route.params.filter == "online-terapi") {
        this.filtered = "OS";
      } else if (this.$route.params.filter == "evde-terapi") {
        this.filtered = "HS";
      } else if (this.$route.params.filter == "yuz-yuze-terapi") {
        this.filtered = "F2FS";
      } else {
        this.filtered = this.$route.params.filter;
      }
    }
    this.$store.dispatch("citysGet", cityInfo).then((value) => {
      this.cityList = value.list;
      try {
        this.city = this.cityList.filter(
          (country) => country.shortname == this.$route.query.city
        )[0].name;
        this.district = this.cityList
          .filter((country) => country.shortname == this.$route.query.city)[0]
          .districts.filter(
            (country) => country.shortname == this.$route.query.district
          )[0].name;
      } catch (error) {}
    });
    this.getList();
  },
  data() {
    return {
      ads: [],
      cityList: [],
      districtList: [],
      info: [],
      list: [],
      city: "",
      district: "",
      search: "",
      serviceList: [],
      currentpage: 1,
      perpage: 12,
      totalsize: 0,
      pagesize: 0,
      isExpertLoad: true,
      filter: "",
      filtered: "",
      soltered: "",
      title: "",
      description: "",
      filterArray: [],
    };
  },
  watch: {
    "$route.query.search": function () {
      this.getList();
    },
    "$route.query.page": function () {
      this.getList();
    },
  },
  computed: {
    pageUrl() {
      var linkArray = [];
      if (this.$route.query.search) {
        linkArray.push("search=" + this.$route.query.search);
      }
      if (this.$route.query.city) {
        linkArray.push("city=" + this.$route.query.city);
      }
      if (this.$route.query.district) {
        linkArray.push("district=" + this.$route.query.district);
      }

      if (this.$route.query.filter) {
          linkArray.push("filter=" + this.$route.query.filter);
      }
      linkArray.push("page=");
      return "?" + linkArray.join("&");
    },
  },
  methods: {
    filterGO() {
      var linkArray = [];
      if (this.$route.query.search) {
        linkArray.push("search=" + this.search);
      } else if (this.search != "") {
        linkArray.push("search=" + this.search);
      }
      if (this.$route.query.city) {
        linkArray.push("city=" + this.$route.query.city);
      }
      if (this.$route.query.district) {
        linkArray.push("district=" + this.$route.query.district);
      }
      linkArray.push("filter=" + this.filtered.split(","));
      this.$router.push("/terapist?" + linkArray.join("&"));
      this.getList();
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filterOpen() {
      $("#exampleModal").modal("show");
    },
    filterClose() {
      $("#exampleModal").modal("hide");
    },
    searchExperience() {
      let experience = document.getElementById("experiencesList").value;
      this.search = experience;
    },
    filterItem(item, i, type) {
      if (this.filtered != "") {
        var a = this.filtered.split("-");
        a.forEach((element) => {
          var b = element.split(".");
          this.filterArray[b[0]] = b[1].split(",");
        });
      }
      if (this.filterArray["_" + i] == undefined) {
        this.filterArray["_" + i] = [];
      }
      if (type == "radio") {
        this.filterArray["_" + i] = [];
      }
      //Listede var mı?
      var found = this.filterArray["_" + i].indexOf(item);
      if (found < 0) {
        this.filterArray["_" + i].push(item);
      } else {
        this.filterArray["_" + i].splice(found, 1);
      }
      var string = [];
      for (var key in this.filterArray) {
        string.push(key + "." + this.filterArray[key]);
      }
      this.filtered = string.join("-").toString();
      if (this.$route.query.page) {
        this.$route.query.page = 1;
        this.currentpage = 1;
      } else {
        this.currentpage = 1;
      }
    },

    getList() {
      this.isExpertLoad = true;
      if (this.$route.query.page || this.$route.query.page > 1) {
        this.currentpage = this.$route.query.page;
      }
      if (this.$route.query.search && this.search != "") {
        this.search = this.$route.query.search;
      }
      if (this.$route.query.filter) {
        this.filtered = this.$route.query.filter;
      }

      if (this.$route.query.filter || this.$route.params.filter) {
        if (this.$route.query.filter) this.filtered = this.$route.query.filter;
        if (this.$route.params.filter) {
          if (this.$route.params.filter == "online-terapi") {
            this.filtered = "_0.OS";
          } else if (this.$route.params.filter == "evde-terapi") {
            this.filtered = "_0.HS";
          } else if (this.$route.params.filter == "yuz-yuze-terapi") {
            this.filtered = "_0.F2FS";
          } else {
            this.filtered = this.$route.params.filter;
          }
        }
      }

      var array = [];
      if (this.filtered != "") {
        var a = this.filtered.split("-");
        a.forEach((element) => {
          var b = element.split(".");
          array.push(b[1].split(","));
        });
      }

      let experienceInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        location: this.$route.query.city,
        province: this.$route.query.district,
        search: this.search,
        filtered: array.toString(),
      };
      this.$store
        .dispatch("serviceUserFilter", experienceInfo)
        .then((value) => {
          this.title = "Tüm Terapistler - Terapi Vitrini";
          this.description =
            "Tüm Terapistler - ihtiyacınız olan terapiyi ve terapisti bulun";
          let _this = this;
          $(document).ready(function () {
            let content = document.getElementById("appcontent").innerHTML;
            _this.$store.dispatch("setMetaContent", [
              _this.title,
              _this.description,
              "",
              "",
              "",
              content,
            ]);
          });
          this.ads = value.ads;
          this.list = value.users;
          this.totalsize = value.size;
          this.pagesize = value.pages;
          this.filter = [];
          this.filter = value.filter;
          this.isExpertLoad = false;
          setTimeout(() => {
            this.filterClose();
          }, 200);
        });
    },
    locationChange() {
      let city = document.querySelector("select[name=city]").value;
      this.districtList = this.cityList.filter(
        (country) => country.shortname == city
      )[0].districts;
      this.$router.push("/terapist-filter?city=" + city);
    },
    disctrictChange() {
      let district = document.querySelector("select[name=district]").value;
      this.$router.push(
        "/terapist-filter?city=" +
          this.$route.query.city +
          "&district=" +
          district +
          ""
      );
    },
  },

  components: {
    Experience,
    ProfileItem,
    ProfileItemSkeleton,
    Search,
    Pagination,
    Item1,
    Breadcrumb,
  },
  mounted() {
    this.backToTop();
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
    });
  },
};
</script>